/**
 * Custom Chakra-UI font sizes
 */
export default {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '18px',
  '2xl': '22px',
  '3xl': '26px',
  '4xl': '34px',
  '5xl': '46px',
  '6xl': '62px',
};
