/**
 * Custom Chakra-UI color palette
 */
export default {
  white: '#fff',
  black: '#000',

  gray: {
    50: '#f8f8f8',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d5d5d5',
    400: '#b6b6b6',
    500: '#979797',
    600: '#888888',
    700: '#5b5b5b',
    800: '#444444',
    900: '#2d2d2d',
  },

  orange: {
    50: '#fef8f1',
    100: '#fef5eb',
    200: '#fce7cd',
    300: '#fbd8af',
    400: '#f7ba73',
    500: '#f49d37',
    600: '#dc8d32',
    700: '#925e21',
    800: '#6e4719',
    900: '#492f11',
  },

  teal: {
    50: '#eef2f3',
    100: '#e6ecee',
    200: '#c1d0d4',
    300: '#9cb4ba',
    400: '#517c86',
    500: '#074452',
    600: '#063d4a',
    700: '#042931',
    800: '#031f25',
    900: '#021419',
  },

  red: {
    50: '#fbf0f0',
    100: '#f9e9e9',
    200: '#efc8c7',
    300: '#e5a6a5',
    400: '#d26462',
    500: '#bf211e',
    600: '#ac1e1b',
    700: '#731412',
    800: '#560f0e',
    900: '#390a09',
  },

  yellow: {
    50: '#fefdf4',
    100: '#fefcef',
    200: '#fef6d6',
    300: '#fdf1be',
    400: '#fbe78d',
    500: '#f9dc5c',
    600: '#e0c653',
    700: '#958437',
    800: '#706329',
    900: '#4b421c',
  },

  cyan: {
    50: '#eef9fc',
    100: '#e7f7fb',
    200: '#c3ecf4',
    300: '#9fe0ed',
    400: '#56c8e0',
    500: '#0eb1d2',
    600: '#0d9fbd',
    700: '#086a7e',
    800: '#06505f',
    900: '#04353f',
  },

  blue: {
    50: '#f0f5f9',
    100: '#eaf0f7',
    200: '#cbdaea',
    300: '#acc4de',
    400: '#6e97c5',
    500: '#306bac',
    600: '#2b609b',
    700: '#1d4067',
    800: '#16304d',
    900: '#0e2034',
  },

  purple: {
    50: '#f9f5fc',
    100: '#f6f0fb',
    200: '#e7daf5',
    300: '#d9c4ee',
    400: '#bd98e2',
    500: '#a06cd5',
    600: '#9061C0',
    700: '#604180',
    800: '#483160',
    900: '#302040',
  },

  pink: {
    50: '#fef7fb',
    100: '#fdf4f9',
    200: '#fbe4f0',
    300: '#f8d3e7',
    400: '#f3b3d4',
    500: '#ee92c2',
    600: '#d683af',
    700: '#8f5874',
    800: '#6b4257',
    900: '#472c3a',
  },

  green: {
    50: '#f5f9f5',
    100: '#f0f7f1',
    200: '#daebdd',
    300: '#c4dfc8',
    400: '#98c69e',
    500: '#6cae75',
    600: '#619d69',
    700: '#416846',
    800: '#314e35',
    900: '#203423',
  },

  turqouise: {
    100: '#f5f9f9',
    200: '#e7efef',
    300: '#d8e6e6',
    400: '#bad3d3',
    500: '#9dc0c0',
    600: '#8dadad',
    700: '#5e7373',
    800: '#475656',
    900: '#2f3a3a',
  },
};
