import { theme } from '@chakra-ui/core';
import type { ITheme } from '@chakra-ui/core';

import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import icons from './icons';

/**
 * Customized Chakra-UI theme
 */
const customTheme: ITheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    ...fonts,
  },
  fontSizes: {
    ...theme.fontSizes,
    ...fontSizes,
  },
  colors: {
    ...theme.colors,
    ...colors,
  },
  icons: {
    ...theme.icons,
    ...icons,
  },
};

export default customTheme;
