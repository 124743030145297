import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import { css, Global } from '@emotion/core';
import '@stripe/stripe-js';

import customTheme from 'theme';
import 'assets/fonts/fonts.css';
import 'react-datepicker/dist/react-datepicker.css';

export const wrapRootElement = ({ element }) => (
  <>
    <ReactQueryDevtools initialIsOpen={false} />
    <ReactQueryConfigProvider>
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <Global
          styles={css`
            * {
              font-family: 'Lexie Readable Regular', sans-serif;
              font-size: 14px;
              .react-datepicker-wrapper {
                width: 100%;
              }
            }
          `}
        />
        {element}
      </ThemeProvider>
    </ReactQueryConfigProvider>
  </>
);
