// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-400-tsx": () => import("./../../../src/pages/400.tsx" /* webpackChunkName: "component---src-pages-400-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-to-calendar-tsx": () => import("./../../../src/pages/add-to-calendar.tsx" /* webpackChunkName: "component---src-pages-add-to-calendar-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-admin-tsx": () => import("./../../../src/pages/login/admin.tsx" /* webpackChunkName: "component---src-pages-login-admin-tsx" */),
  "component---src-pages-login-parent-tsx": () => import("./../../../src/pages/login/parent.tsx" /* webpackChunkName: "component---src-pages-login-parent-tsx" */),
  "component---src-pages-login-teacher-tsx": () => import("./../../../src/pages/login/teacher.tsx" /* webpackChunkName: "component---src-pages-login-teacher-tsx" */),
  "component---src-pages-parent-tsx": () => import("./../../../src/pages/parent.tsx" /* webpackChunkName: "component---src-pages-parent-tsx" */),
  "component---src-pages-signup-parent-tsx": () => import("./../../../src/pages/signup/parent.tsx" /* webpackChunkName: "component---src-pages-signup-parent-tsx" */),
  "component---src-pages-signup-summer-tsx": () => import("./../../../src/pages/signup/summer.tsx" /* webpackChunkName: "component---src-pages-signup-summer-tsx" */),
  "component---src-pages-teacher-tsx": () => import("./../../../src/pages/teacher.tsx" /* webpackChunkName: "component---src-pages-teacher-tsx" */)
}

