import React from 'react';

/**
 * Custom Chakra-UI icon system
 */
export default {
  calendar: {
    path: (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M26 0v2.001h-4.001V0H8v2.001H3.999V0H0v32h30V0h-4zM2 30.001h26V8H2v22.001zM21.999 16H26v-4h-4.001v4zM16 16h4v-4h-4v4zm-6 0h4.001v-4H10v4zm11.999 6.001H26V18h-4.001v4.001zm-5.999 0h4V18h-4v4.001zm-6 0h4.001V18H10v4.001zm-6.001 0H8V18H3.999v4.001zm12.001 6h4V24h-4v4.001zm-6 0h4.001V24H10v4.001zm-6.001 0H8V24H3.999v4.001z"
      />
    ),
    viewBox: '0 0 30 32',
  },
  'dollar-sign-circle': {
    path: (
      <path
        fill="#074452"
        fillRule="evenodd"
        d="M10 12h1v-2h-1v2zM7 8h1V6H7v2zm2.6.889V6.444H12V5.222H9.6V4H8.4v1.222H6v4.89h2.4v2.444H6v1.222h2.4V15h1.2v-1.222H12v-4.89H9.6zM9 16A7 7 0 119 2a7 7 0 010 14zM9 0a9 9 0 100 18A9 9 0 009 0z"
      />
    ),
    viewBox: '0 0 18 18',
  },
  'edit-circle': {
    path: (
      <>
        <defs>
          <path id="a" d="M0 0h20v20H0z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <circle cx="20" cy="20" r="20" fill="#fff" stroke="#074452" />
          <g transform="translate(10 10)">
            <mask id="b" fill="#fff">
              <use href="#a" />
            </mask>
            <path
              fill="#014353"
              d="M13.976 7.101l-8.75 8.75-1.078-1.077 8.75-8.75L13.976 7.1zM1.25 14.375L0 20l5.625-1.25L17.188 7.187l-4.375-4.375L1.25 14.376zM16.875 0a3.126 3.126 0 012.5 5l-1.25 1.25-4.375-4.375L15 .625A3.11 3.11 0 0116.875 0z"
              mask="url(#b)"
            />
          </g>
        </g>
      </>
    ),
    viewBox: '0 0 42 42',
  },
  email: {
    path: (
      <g fill="currentColor">
        <path d="M17.59 0H.41A.407.407 0 000 .406v12.188c0 .224.183.406.41.406h17.18c.227 0 .41-.182.41-.406V.406A.408.408 0 0017.59 0zm-.408 11.205l-5.068-5.032-.578.574 5.478 5.44H.986l5.478-5.44-.578-.574-5.068 5.032V.812h16.364v10.393z" />
        <path d="M16.192 1L9 8.053 1.808 1H1v.317l7.717 7.568c.157.153.41.153.566 0L17 1.317V1h-.808z" />
      </g>
    ),
    viewBox: '0 0 18 12',
  },
  flask: {
    path: (
      <>
        <defs>
          <path id="a" d="M0 0h15v16H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="unique" fill="#fff">
            <use href="#a" />
          </mask>
          <path
            fill="#014353"
            d="M3.23 10l3.262-5.39V1h2.017v3.61l3.26 5.39H3.232zm11.27 2.57L9.517 4.335V1h.504a.504.504 0 0 0 .504-.5c0-.275-.227-.5-.504-.5H4.98a.504.504 0 0 0-.504.5c0 .275.227.5.504.5h.504v3.335L.5 12.57C-.642 14.456.24 16 2.458 16h10.084c2.218 0 3.1-1.543 1.958-3.43z"
            mask="url(#unique)"
          />
        </g>
      </>
    ),
    viewBox: '0 0 15 16',
  },
  house: {
    path: (
      <path
        fill="#074452"
        fillRule="evenodd"
        d="M8.5 0L0 8.296v.519h2.124V14h5.313v-3.111h2.125V14h5.313V8.815H17v-.519l-3.188-3.11V.518h-2.125V3.11z"
      />
    ),
    viewBox: '0 0 17 14',
  },
  'info-outline': {
    path: (
      <g fill="currentColor">
        <path d="M12.5 0C5.607 0 0 5.608 0 12.5 0 19.393 5.607 25 12.5 25 19.392 25 25 19.393 25 12.5 25 5.608 19.392 0 12.5 0zm0 22.727c-5.64 0-10.227-4.588-10.227-10.227C2.273 6.86 6.86 2.273 12.5 2.273c5.64 0 10.227 4.588 10.227 10.227 0 5.64-4.588 10.227-10.227 10.227z" />
        <path d="M12.5 6c-.827 0-1.5.673-1.5 1.5S11.673 9 12.5 9 14 8.327 14 7.5 13.327 6 12.5 6zm0 5c-.828 0-1.5.504-1.5 1.125v6.75c0 .621.672 1.125 1.5 1.125s1.5-.504 1.5-1.125v-6.75c0-.621-.672-1.125-1.5-1.125z" />
      </g>
    ),
    viewBox: '0 0 25 25',
  },
  menu: {
    path: (
      <g fill="none" fillRule="evenodd" stroke="#074452">
        <rect width="13" height="1" x=".5" y="10.5" rx=".5" />
        <rect width="13" height="1" x=".5" y="5.5" rx=".5" />
        <rect width="13" height="1" x=".5" y=".5" rx=".5" />
      </g>
    ),
    viewBox: '0 0 14 12',
  },
  person: {
    path: (
      <path
        d="M5.5 8C7.433 8 9 6.21 9 4s-.514-4-3.5-4C2.515 0 2 1.79 2 4s1.567 4 3.5 4zM.007 13.5c-.006-.973-.012-.274 0 0zm10.987 1.103C10.94 10.78 10.495 9.69 7.094 9c0 0-.478.686-1.594.686S3.906 9 3.906 9C.542 9.682.07 10.756.008 14.48c-.005.303-.007.32-.008.284v.402S.81 17 5.5 17c4.69 0 5.5-1.834 5.5-1.834v-.297c0 .021-.003-.02-.006-.266z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 11 17',
  },
  time: {
    path: (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 28C9.373 28 4 22.627 4 16S9.373 4 16 4s12 5.373 12 12-5.373 12-12 12zm0-28C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm4.586 23.414L14 16.828V8h4v7.172l5.414 5.414-2.828 2.828z"
      />
    ),
    viewBox: '0 0 32 32',
  },
  'validation-circle': {
    path: (
      <g fill="none" transform="translate(1 1)">
        <circle
          cx="44"
          cy="44"
          r="44"
          fill="#fff"
          stroke="#014353"
          strokeWidth="2"
        />
        <path
          fill="#f69e26"
          d="M70.421 29.693L37.374 62.808a.738.738 0 01-1.02 0L17.587 44.002a.742.742 0 010-1.022l4.556-4.565a.738.738 0 011.02 0L36.83 52.11l27.947-28.004a.738.738 0 011.02 0l4.556 4.565c.34.272.34.681.068 1.022z"
        />
      </g>
    ),
    viewBox: '0 0 90 90',
  },
};
